import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VForm,
    {
      ref: "form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.resetPassword($event)
        }
      }
    },
    [
      _c("div", { staticClass: "H6-Primary-Left" }, [_vm._v("Welcome to")]),
      _vm.$route.query.type === "runner"
        ? [
            _c("div", { staticClass: "H4-Primary-Left" }, [_vm._v("Bolter")]),
            _c(
              "div",
              {
                staticClass:
                  "Subtitle-2-Selected-On-Secondary-Medium-Emphasis-Left"
              },
              [_vm._v("\n      Set up your new password below\n    ")]
            )
          ]
        : _c("div", { staticClass: "H4-Primary-Left" }, [
            _vm._v("Admin Panel")
          ]),
      _c(VTextField, {
        attrs: {
          label: "Password",
          type: _vm.showPassword ? "text" : "password",
          "error-messages": _vm.errorMessages,
          "append-icon": _vm.showPasswordIcon,
          message: _vm.message
        },
        on: {
          "click:append": function($event) {
            _vm.showPassword = !_vm.showPassword
          },
          focus: function($event) {
            _vm.validate_password = false
          }
        },
        model: {
          value: _vm.password,
          callback: function($$v) {
            _vm.password = $$v
          },
          expression: "password"
        }
      }),
      _c("password-validator", {
        attrs: {
          validator: _vm.validator,
          validate_password: _vm.validate_password
        },
        on: {
          "update:validator": function($event) {
            _vm.validator = $event
          },
          "update:validate_password": function($event) {
            _vm.validate_password = $event
          }
        }
      }),
      _c(VTextField, {
        attrs: {
          label: "Confirm Password",
          type: _vm.showConfirmPassword ? "text" : "password",
          "error-messages": _vm.confirmPasswordErrors,
          "append-icon": _vm.showConfirmPasswordIcon
        },
        on: {
          "click:append": function($event) {
            _vm.showConfirmPassword = !_vm.showConfirmPassword
          },
          focus: function($event) {
            _vm.validate_password = true
          }
        },
        model: {
          value: _vm.confirm_password,
          callback: function($$v) {
            _vm.confirm_password = $$v
          },
          expression: "confirm_password"
        }
      }),
      _c(
        VBtn,
        {
          staticClass: "set-password-btn",
          attrs: {
            disabled: !_vm.valid,
            color: "primary Button-Selected-On-Primary-High-Emphasis-Center",
            type: "submit",
            loading: _vm.loading
          }
        },
        [_vm._v("Set Password")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }