<template>
  <ul class="list_style">
    <li>
      <v-progress-linear
        id="password_strength_bar"
        :value="(getPasswordStrength() / maxPasswordStrength) * 100"
        :color="getPasswordStrengthBarColor()"
      ></v-progress-linear>
    </li>
    <li :class="this.getMessageState(this.validator.has_minimum_length)">
      <v-icon small :color="this.getIconColor(this.validator.has_minimum_length)">
        {{ this.getIcon(this.validator.has_minimum_length) }}
      </v-icon>
      At least {{ getLengthRequirement() }} characters
    </li>
    <li :class="this.getMessageState(this.validator.has_uppercase)">
      <v-icon small :color="this.getIconColor(this.validator.has_uppercase)">
        {{ this.getIcon(this.validator.has_uppercase) }}
      </v-icon>
      An uppercase character
    </li>
    <li :class="this.getMessageState(this.validator.has_lowercase)">
      <v-icon small :color="this.getIconColor(this.validator.has_lowercase)">
        {{ this.getIcon(this.validator.has_lowercase) }}
      </v-icon>
      A lowercase character
    </li>
    <li :class="this.getMessageState(this.validator.has_number || this.validator.has_symbol)">
      <v-icon
        small
        :color="this.getIconColor(this.validator.has_number || this.validator.has_symbol)"
      >
        {{ this.getIcon(this.validator.has_number || this.validator.has_symbol) }}
      </v-icon>
      A number or a special character
    </li>
  </ul>
</template>

<script>
export default {
  props: ['validator', 'validate_password'],
  data() {
    return {
      maxPasswordStrength: Object.getOwnPropertyNames(this.validator).reduce((prev, curr) => {
        return typeof this.validator[curr] === 'boolean' ? prev + 1 : prev;
      }, 0),
    };
  },
  methods: {
    getMessageState(requirement) {
      if (this.validate_password && !requirement) {
        return 'error_state';
      }
      if (requirement) {
        return 'blue_state';
      }
      return 'valid_state';
    },
    getIconColor(requirement) {
      if (this.validate_password && !requirement) {
        return 'red';
      }
      if (requirement) {
        return 'blue';
      }
      return null;
    },
    getIcon(requirement) {
      return this.validate_password && !requirement
        ? 'mdi-close-circle-outline'
        : 'mdi-check-circle-outline';
    },
    getLengthRequirement() {
      return this.$route && this.$route.query && this.$route.query.role === 'admin' ? 15 : 9;
    },
    getPasswordStrength() {
      return Object.getOwnPropertyNames(this.validator).reduce((prev, curr) => {
        if (typeof this.validator[curr] === 'boolean') {
          return prev + (this.validator[curr] ? 1 : 0);
        }
        return prev;
      }, 0);
    },
    getPasswordStrengthBarColor() {
      const currStr = this.getPasswordStrength();

      if (currStr === 0) return 'grey';

      const ratio = currStr / this.maxPasswordStrength;

      if (ratio < 0.4) return 'red';
      if (ratio >= 0.8) return 'green';
      return 'yellow';
    },
  },
};
</script>

<style scoped>
.list_style {
  list-style-type: none;
  margin-left: -25px;
  margin-bottom: 25px;
}
.list_style >>> li {
  font-family: Avenir;
  letter-spacing: 0.4px;
}
.error_state {
  color: #ff5252;
}
.valid_state {
  color: rgba(0, 0, 0, 0.6);
}
.blue_state {
  color: #0d73d8;
}
.list_style >>> .v-icon {
  margin-bottom: 2px;
}

#password_strength_bar {
  width: 100%;
  margin-top: 0;
}
</style>
