import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "list_style" }, [
    _c(
      "li",
      [
        _c(VProgressLinear, {
          attrs: {
            id: "password_strength_bar",
            value: (_vm.getPasswordStrength() / _vm.maxPasswordStrength) * 100,
            color: _vm.getPasswordStrengthBarColor()
          }
        })
      ],
      1
    ),
    _c(
      "li",
      { class: this.getMessageState(this.validator.has_minimum_length) },
      [
        _c(
          VIcon,
          {
            attrs: {
              small: "",
              color: this.getIconColor(this.validator.has_minimum_length)
            }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(this.getIcon(this.validator.has_minimum_length)) +
                "\n    "
            )
          ]
        ),
        _vm._v(
          "\n    At least " +
            _vm._s(_vm.getLengthRequirement()) +
            " characters\n  "
        )
      ],
      1
    ),
    _c(
      "li",
      { class: this.getMessageState(this.validator.has_uppercase) },
      [
        _c(
          VIcon,
          {
            attrs: {
              small: "",
              color: this.getIconColor(this.validator.has_uppercase)
            }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(this.getIcon(this.validator.has_uppercase)) +
                "\n    "
            )
          ]
        ),
        _vm._v("\n    An uppercase character\n  ")
      ],
      1
    ),
    _c(
      "li",
      { class: this.getMessageState(this.validator.has_lowercase) },
      [
        _c(
          VIcon,
          {
            attrs: {
              small: "",
              color: this.getIconColor(this.validator.has_lowercase)
            }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(this.getIcon(this.validator.has_lowercase)) +
                "\n    "
            )
          ]
        ),
        _vm._v("\n    A lowercase character\n  ")
      ],
      1
    ),
    _c(
      "li",
      {
        class: this.getMessageState(
          this.validator.has_number || this.validator.has_symbol
        )
      },
      [
        _c(
          VIcon,
          {
            attrs: {
              small: "",
              color: this.getIconColor(
                this.validator.has_number || this.validator.has_symbol
              )
            }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  this.getIcon(
                    this.validator.has_number || this.validator.has_symbol
                  )
                ) +
                "\n    "
            )
          ]
        ),
        _vm._v("\n    A number or a special character\n  ")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }