<template>
  <v-form ref="form" @submit.prevent="resetPassword">
    <div class="H6-Primary-Left">Welcome to</div>
    <template v-if="$route.query.type === 'runner'">
      <div class="H4-Primary-Left">Bolter</div>
      <div class="Subtitle-2-Selected-On-Secondary-Medium-Emphasis-Left">
        Set up your new password below
      </div>
    </template>
    <div v-else class="H4-Primary-Left">Admin Panel</div>
    <v-text-field
      label="Password"
      v-model="password"
      :type="showPassword ? 'text' : 'password'"
      :error-messages="errorMessages"
      :append-icon="showPasswordIcon"
      @click:append="showPassword = !showPassword"
      :message="message"
      @focus="validate_password = false"
    ></v-text-field>
    <password-validator :validator.sync="validator" :validate_password.sync="validate_password" />
    <v-text-field
      label="Confirm Password"
      v-model="confirm_password"
      :type="showConfirmPassword ? 'text' : 'password'"
      :error-messages="confirmPasswordErrors"
      :append-icon="showConfirmPasswordIcon"
      @click:append="showConfirmPassword = !showConfirmPassword"
      @focus="validate_password = true"
    ></v-text-field>
    <v-btn
      :disabled="!valid"
      class="set-password-btn"
      color="primary Button-Selected-On-Primary-High-Emphasis-Center"
      type="submit"
      :loading="loading"
      >Set Password</v-btn
    >
  </v-form>
</template>

<script>
import { Realms } from '@/constants';
import PasswordValidator from './passwordValidation';

export default {
  components: {
    PasswordValidator,
  },
  data: () => ({
    email: '',
    password: '',
    confirm_password: '',
    showPassword: false,
    showConfirmPassword: false,
    status: [],
    errorMessages: [],
    message: '',
    reset_token: '',
    stage: '',
    loading: false,
    validate_password: false,
    validator: {
      has_minimum_length: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_symbol: false,
    },
  }),

  computed: {
    user_id() {
      return this.$route.query.id;
    },
    showPasswordIcon() {
      if (this.password.length === 0) {
        return '';
      }
      if (this.showPassword) {
        return 'mdi-eye-off';
      }
      return 'mdi-eye';
    },
    showConfirmPasswordIcon() {
      if (this.password.length === 0) {
        return '';
      }
      if (this.showConfirmPassword) {
        return 'mdi-eye-off';
      }
      return 'mdi-eye';
    },
    confirmPasswordErrors() {
      return this.confirm_password !== this.password && this.validate_password
        ? " ⓧ Passwords don't match"
        : '';
    },
    valid() {
      return (
        this.validator.has_minimum_length &&
        (this.validator.has_number || this.validator.has_symbol) &&
        this.validator.has_lowercase &&
        this.validator.has_uppercase &&
        this.password === this.confirm_password
      );
    },
  },
  watch: {
    password() {
      const proper_length =
        this.$route && this.$route.query && this.$route.query.role === 'admin' ? 15 : 9;
      this.validator.has_minimum_length = this.password.length >= proper_length;
      this.validator.has_number = /\d/.test(this.password);
      this.validator.has_lowercase = /[a-z]/.test(this.password);
      this.validator.has_uppercase = /[A-Z]/.test(this.password);
      // eslint-disable-next-line no-useless-escape
      this.validator.has_symbol = /[`~\\!@#$%^&*(\\)\-_=+\\[\\{\\}\\\]|;:'",<.>\\\/?€£¥₹§±]/.test(
        this.password,
      );
    },
  },

  methods: {
    realm() {
      if (this.$route.query.type === 'runner') {
        return Realms.bolter;
      }
      return Realms.cdl;
    },
    async resetPassword() {
      try {
        if (this.errorMessages.length > 0) {
          this.errorMessages = [];
          this.valid = true;
        }

        if (!this.valid) {
          return;
        }
        this.loading = true;
        const response = await this.api.post(
          `/user/${this.user_id}/resetpassword`,
          {
            password: this.password,
            realm: this.realm(),
          },
          {
            params: {
              reset_token: this.reset_token,
            },
          },
        );
        if (response.data.success) {
          this.message = 'Password successfully set';
          this.$toast('Password updated successfully');
          let routeName = 'login';
          let query = {};
          if (this.$route.query.type === 'runner') {
            routeName = 'nextSteps';
            query = {
              type: 'runner',
            };
          }
          this.$router.push({
            name: routeName,
            query,
          });
        } else {
          this.$toast.error('Something happened while we tried to set your password.');
        }
      } catch (err) {
        if (err?.response?.data?.code === 422.1) {
          this.$toast.error(err?.response?.data?.error);
        } else {
          this.$toast.error('Could not reset your password, contact support.');
        }
      }
      this.loading = false;
    },
  },

  created() {
    this.email = this.$store.state.adminPanel.loginEmail;
  },

  mounted() {
    this.reset_token = decodeURIComponent(this.$route.query.reset_token).replace(/\s/gi, '+');
    this.stage = this.$route.query.stage;
    if (!this.user_id || !this.reset_token) {
      this.$router.push({
        name: 'login',
      });
    }
  },
};
</script>

<style scoped>
.set-password-btn {
  margin-left: 0;
  margin-top: 20px;
}
</style>
